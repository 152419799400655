"use client";

import CartButton from "./cartButton";

export default function Nav({ cartItems }: { cartItems: any }) {
  return (
    <nav className="js-nav nav flex justify-start">
      <ul className="flex space-x-4">
        <li>
          <a href="/">Начало</a>
        </li>

        <li>
          <a href="/products">Продукти</a>
        </li>

        <li>
          <a href="/discounts">Намаления</a>
        </li>

        {/* <li className="nav-item-mobile">
          <a href="/checkout">Количка</a>
        </li> */}

        <li className="  p-2 flex md:hidden items-center justify-center gap-2 ">
          {" "}
          {/* Ensure padding/margin */}
          <a href="/cart" className="text-white">
            Количка
          </a>
          <CartButton tab="mobile" itemsLength={cartItems.length} />
        </li>
      </ul>
    </nav>
  );
}
