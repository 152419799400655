"use client";

import Image from "next/image";
import Link from "next/link";

export default function CartButton({
  itemsLength = 0,
  tab
}: {
  itemsLength?: number;
  tab:any;
}) {
  // const handleOpenSidebar = () => {
  //   const sidebar = document.querySelector(".js-sidebar");
  //   sidebar?.classList.add("visible");

  //   const sidebarCloseButton = document.querySelector(".js-sidebar-close");
  //   sidebarCloseButton?.addEventListener("click", (e) => {
  //     sidebar?.classList.remove("visible");
  //   });
  // };

  return (
    <Link href="/cart" className="relative icon  ">
      {itemsLength > 0 && (
        <span className="absolute bg-red-600 text-white w-6 h-6 flex items-center justify-center rounded-full top-[-20px] right-[-20px] text-sm">
          {itemsLength}
        </span>
      )}
      {tab =="mobile"?
      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="white">
      <path d="M7 4h-2v2h2v-2zm0 2h10v-2h2v2h2v2h-1.757l-1.684 8.69c-.098.499-.526.86-1.033.86h-11.052c-.507 0-.935-.361-1.033-.86l-1.684-8.69h-1.757v-2h2v-2h2v2zm-1.11 2l1.526 7.923h9.167l1.526-7.923h-12.219zm1.361 10.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm10 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z"/>
    </svg>
    :
    <Image src="/cart.svg" alt="cart" width={150} height={150} />

    }
    </Link>
  );
}
