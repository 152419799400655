"use client";

export default function NavTrigger() {
    const handleHamburgerClick = () => {
		let hamburger = document.querySelector('.js-nav-trigger');
		hamburger?.classList.toggle('active');
        
		let menu = document.querySelector('.js-nav');
		menu?.classList.toggle('visible');
		menu?.classList.toggle('active');
	}

    return (
        <button className={`js-nav-trigger nav-trigger w-5 h-5`} onClick={handleHamburgerClick}>
            <span></span>
            <span></span>
            <span></span>
        </button>
    )
}