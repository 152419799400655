const contentfulManagement = require("contentful-management");
export default async function fetchGraphQL(query: string) {
  return fetch(
    `https://graphql.contentful.com/content/v1/spaces/4luvk4mlmiay?access_token=tK2w2-qHuqC_wFQlCx7-GjLZqCs3u7kbW9O3-_taDQE`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    }
  ).then((res) => res.json());
}

// Create a client using your Contentful management access token
export const contentfulClient = contentfulManagement.createClient({
  accessToken: "CFPAT-oZdw1s3kTEUWnuPup9iaRut__NIu_frAU891-vgMzjo", // Replace with your Contentful management token
});
export const createnNewsletterSubscription = async (email: string) => {
  try {
    // Fetch your space
    const space = await contentfulClient.getSpace("4luvk4mlmiay"); // Replace with your space ID

    // Fetch the environment (usually "master" or the environment you are working in)
    const environment = await space.getEnvironment("master"); // Or your specific environment

    // Create a new entry of type "promoCodes" (replace with your content type ID)
    const newEntry = await environment.createEntry("newsletterSubscriptions", {
      fields: {
        email: {
          "en-US": email, // Replace 'en-US' with the appropriate locale if needed
        },
      },
    });

    // Publish the entry (optional, but necessary if you want it to be visible)
    const publishedEntry = await newEntry.publish();

    console.log(
      "newsletterSubscriptions created and published:",
      publishedEntry
    );
    return { success: true, data: publishedEntry };
  } catch (error) {
    console.error("Error creating promo code:", error);
    return { success: false, data: "Newsletter already subscribed." };
  }
};
