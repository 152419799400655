"use client";
import React, {
  createContext,
  useState,
  ReactNode,
  FC,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

// Define types for your context
interface AppContextType {
  cartItems: Product[]; // Replace `Product` with the actual type for your cart items
  setCartItems: Dispatch<SetStateAction<Product[]>>;
}

// Create the context with a default value
const AppContext = createContext<AppContextType | undefined>(undefined);

type AppProviderProps = {
  children: ReactNode;
};

// Define a type for the product (you should replace this with your actual product type)
interface Product {
  id: number; // Example field; adjust according to your product structure
  name: string;
  price: number;
  finalPrice: number;
  qty: number;
}

const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState<any[]>([]);

  return (
    <AppContext.Provider value={{ cartItems, setCartItems }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
};

export { AppProvider, AppContext };
